<template>
  <nav>
    <v-toolbar color="black">
      <v-toolbar-title >
        <v-btn :ripple="false" color="yellow" class="font-weight-bold text-h4" text to="/">zep.beer</v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text color="white" to="/about">
          Über uns
        </v-btn>
        <v-btn text color="white" to="/products">
          Produkte
        </v-btn>
        <v-btn text color="white" to="/blog">
          Blog
        </v-btn>
        <v-btn text color="white" to="/contact">
          Kontakt
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  components: {},
  data() {
    return {};
  },
};
</script>

<style>
router-link {
  text-decoration: none;
}
</style>
