<template>
  <v-container fluid ma-0>
    <div v-if="imgRight">
      <v-row align="center" id="sectionContainerDark" class="pa-8">
        <v-col xl="6" lg="6" md="6" sm="12" xs="12" class="px-8">
          <v-container class="text-overline text-left py-2 yellow--text">
            {{ overline }}
          </v-container>
          <v-container class="text-h3 text-left font-weight-bold py-0">
            {{ title }}
          </v-container>
          <v-container class="text-left py-10 text-body1" v-html="text"/>
          <div v-if="btnText">
            <v-btn
              color="yellow"
              class="mx-6 px-8 float-left font-weight-bold"
              :to="btnTo"
            >
              {{ btnText }}
            </v-btn>
          </div>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="12" xs="12" class="pa-8">
          <v-img contain :src="imgSrc" alt="About Image" height="200px" />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row align="center" id="sectionContainerLight" class="pa-8">
        <v-col xl="6" lg="6" md="6" sm="12" xs="12" class="pa-8">
          <v-img contain :src="imgSrc" alt="About Image" height="200px" />
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="12" xs="12" class="px-8">
          <v-container class="text-overline text-left py-2 yellow--text">
            {{ overline }}
          </v-container>
          <v-container class="text-h3 text-left font-weight-bold py-0">
            {{ title }}
          </v-container>
          <v-container class="text-left py-10 text-body1" v-html="text">
          </v-container>
          <div v-if="btnText">
            <v-btn
              color="yellow"
              class="mx-6 px-8 float-left font-weight-bold"
              :to="btnTo"
            >
              {{ btnText }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    title: String,
    text: String,
    overline: String,
    imgRight: Boolean,
    imgSrc: String,
    btnText: String,
    btnTo: String,
  },
};
</script>

<style>
#sectionContainerDark {
  min-height: 100vh;
  background: #121212;
  color: white;
}
#sectionContainerLight {
  min-height: 100vh;
  background: #fafafa;
  color: #121212;
}

.sectionBtn {
  float: left;
}
</style>
