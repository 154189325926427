<template>
  <v-container fluid pa-0 ma-0>
    <v-container fluid style="height: 100vh" class="text-h1 text-center font-weight-medium pa-0 ma-0" padless>
      <v-img 
        width="100vw"
        height="100vh"
        src="../assets/zep-example-product.jpeg"
        id="startImg"
      >
      <h1>Zep.Beer</h1>
      </v-img> 
    </v-container>
    <about-section
      title="Yo dat sind wir halt"
      text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
      :imgSrc="require('../assets/logo.png')"
      :imgRight="true"
      btnText="Mehr dazu..."
      btnTo="/about"
      overline="Über uns"
    />
    <about-section
      title="Dat gibet bei uns alles"
      text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
      :imgSrc="require('../assets/logo.png')"
      :imgRight="false"
      btnText="Mehr dazu..."
      btnTo="/products"
      overline="Produkte"
    />
    <about-section
      title="So nen fancy hipster Blog"
      text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
      :imgSrc="require('../assets/logo.png')"
      :imgRight="true"
      btnText="Mehr dazu..."
      btnTo="/blog"
      overline="Blog"
    />
    <about-section
      title="Lass mal schnacken"
      text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
      :imgSrc="require('../assets/logo.png')"
      :imgRight="false"
      btnText="Mehr dazu..."
      btnTo="/contact"
      overline="Kontakt"
    />
  </v-container>
</template>

<script>
import AboutSection from "../components/AboutSection.vue";

export default {
  name: "Home",

  components: {
    AboutSection,
  },
};
</script>

<style scoped>
  h1{
    color: white;
    padding-top: 15%;
  }

  #startImg {
    filter: grayscale(95%);
  }
</style>
