<template>
  <v-footer color="black white--text">
    <v-container>
      <v-row no-gutters class="mt-6">
        <v-col cols="3">
          <v-btn color="yellow" class="font-weight-bold text-h4" text to="/"
            >zep.beer</v-btn
          >
        </v-col>
        <v-col  cols="6">
          <v-btn text color="white" to="/about">
            Über uns
          </v-btn>
          <v-btn text color="white" to="/products">
            Produkte
          </v-btn>
          <v-btn text color="white" to="/blog">
            Blog
          </v-btn>
          <v-btn text color="white" to="/contact">
            Kontakt
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn color="transparent white--text" href="https://www.instagram.com/zep_beer/">
            <v-icon class="mr-4">mdi-instagram</v-icon>
            @zep_beer
          </v-btn>
        </v-col>
      </v-row>
      <v-spacer  />
      <v-row class="mt-6">
        <v-col cols="12">
          2021 - Zep 
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {};
</script>

<style></style>
